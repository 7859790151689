// @flow

import * as React from 'react'

type Props = {
  src: string,
  alt: string,
}

export const Image = ({ src, alt, ...props }: Props) => {
  return <img src={src} alt={alt} {...props} />
}
