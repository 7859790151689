// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { themes } from '@toggl/style'
import { ThemeProvider } from 'emotion-theming'
import {
  Button,
  CallToAction,
  Section,
  Card,
  Carousel,
  Hero,
  Logo,
  text,
  hooks,
} from '@toggl/ui'
import { Image } from 'components/image'
import { Video } from 'components/video'
import { colors, media } from '@toggl/style'

import IndexLayout from '../layouts'
import LayoutGlobal from '../components/LayoutGlobal'

import mapEstonia from '../../static/images/uploads/map-estonia.png'
import bg from '../../static/images/uploads/hero-flamingo.jpg'
import { Link } from 'gatsby'
// $FlowFixMe
import yamlData from '../../data/global/pages/mission.yml'
import type { MissionPageData } from 'types'
import MDX from 'components/mdx'
import ball from 'images/uploads/ball.png'
import float from 'images/uploads/float.png'
import computer from 'images/uploads/computer.png'
import { withPrefix } from 'gatsby'

const GlobalMissionPage = () => {
  const { sections }: MissionPageData = yamlData
  const isDesktop = hooks.useMediaQuery(media.mqbp[1])
  const HeroComponent = ({ children }) => {
    return (
      <Hero.Wrapper bgImage={bg}>
        {children}

        <Hero.ContentWrapper>
          <Hero.Title>
            <HeaderWrapper>
              <MDX>{sections.hero.heading}</MDX>
            </HeaderWrapper>
          </Hero.Title>
        </Hero.ContentWrapper>
      </Hero.Wrapper>
    )
  }

  const missionCarousel = (
    <Carousel.DotMobile>
      {sections.traits.items.map((item, index) => (
        <Card.ReadMore
          key={index}
          category={item.title}
          title={item.description}
          image={
            <Image src={withPrefix(item.image.src)} alt={item.image.alt} />
          }
        />
      ))}
    </Carousel.DotMobile>
  )

  return (
    <IndexLayout>
      <LayoutGlobal Hero={HeroComponent}>
        <CarouselSectionBlock>
          <Section.Block>
            <MDX>{sections.traits.heading}</MDX>
            {isDesktop && missionCarousel}
          </Section.Block>
          {!isDesktop && missionCarousel}
        </CarouselSectionBlock>

        <OurStorySection>
          <Section.Block background={colors.darkPurple}>
            <OurStory>
              <OurStoryHeaderWrapper>
                <MDX>{sections.ourStory.heading}</MDX>
                <MDX>{sections.ourStory.items[0].description}</MDX>
              </OurStoryHeaderWrapper>
              <ImgMap src={mapEstonia} />
            </OurStory>
          </Section.Block>

          <Section.Skewed
            color={colors.fadedPurple}
            backgroundColor={colors.darkPurple}
            skewedTopMargin={'170px'}
          >
            <SkewedTextSectionWrapper>
              <SkewedTextLeftSectionWrapper>
                <MDX>{sections.ourStory.items[1].description}</MDX>
              </SkewedTextLeftSectionWrapper>

              <SkewedTextRightSectionWrapper>
                <SkewedTextRightSectionP>
                  <MDX p={text.P2}>
                    {sections.ourStory.items[2].description}
                  </MDX>
                </SkewedTextRightSectionP>
              </SkewedTextRightSectionWrapper>
            </SkewedTextSectionWrapper>
          </Section.Skewed>
        </OurStorySection>

        <TogglsTools>
          {isDesktop && (
            <Image
              src={sections.togglsTools.file}
              alt={'Work smarter, live happier'}
            />
          )}
          <TogglsToolsHeader>
            <MDX>{sections.togglsTools.heading}</MDX>
          </TogglsToolsHeader>

          <Carousel.DotMobile>
            {sections.togglsTools.items.map((item, index) => {
              return (
                <ThemeProvider theme={themes[item.team]} key={index}>
                  <Card.ProductAlternate
                    title={item.description}
                    Logo={Logo[item.team]}
                    // image={<Image src={item.file} alt={item.team} />}
                    video={<Video src={withPrefix(item.file)} />}
                    Cta={() => (
                      <a
                        href={`/${item.team}/`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button.Primary>{item.cta.text}</Button.Primary>
                      </a>
                    )}
                  />
                </ThemeProvider>
              )
            })}
          </Carousel.DotMobile>
        </TogglsTools>

        <TogglsToolsBottom>
          <TogglsToolsBottomHeaderMobile>
            <MDX>{sections.togglsTools.heading2}</MDX>
          </TogglsToolsBottomHeaderMobile>

          <TogglsToolsBottomHeaderDesktop>
            <StyledVideo src={sections.togglsTools.file} />
            <TogglsToolsBottomHeaderDesktopH1>
              <MDX>{sections.togglsTools.heading2}</MDX>
              <MDX>{sections.togglsTools.paragraph}</MDX>
            </TogglsToolsBottomHeaderDesktopH1>
          </TogglsToolsBottomHeaderDesktop>
        </TogglsToolsBottom>

        <TogglsManifesto>
          <Section.Skewed
            color={colors.darkPurple}
            backgroundColor={colors.fadedPurple}
            skewedTopMargin={'180px'}
            left={'-200px'}
          >
            <TogglsManifestoWrapper>
              <TogglsManifestoHeader>
                <MDX>{sections.workSmarterLiveHappier.heading}</MDX>
              </TogglsManifestoHeader>

              <TogglsManifestoTextColumns>
                {sections.workSmarterLiveHappier.items.map((item, index) => {
                  return (
                    <div key={index}>
                      <MDX p={text.P2}>{item.title}</MDX>
                      <MDX p={text.P2}>{item.description}</MDX>
                    </div>
                  )
                })}
              </TogglsManifestoTextColumns>
            </TogglsManifestoWrapper>
          </Section.Skewed>
        </TogglsManifesto>

        <Section.CallToAction
          images={{
            topLeft: '',
            topRight: ball,
            bottomLeft: computer,
            bottomRight: float,
          }}
        >
          <CallToAction
            size={2}
            color={colors.darkPurple}
            title={sections.interestedInWorking.heading}
            Cta={() => (
              <Link to={sections.interestedInWorking.cta.href}>
                <Button.Alternate>
                  {sections.interestedInWorking.cta.text}
                </Button.Alternate>
              </Link>
            )}
          />
        </Section.CallToAction>
      </LayoutGlobal>
    </IndexLayout>
  )
}

const StyledVideo = styled(Video)`
  max-width: 460px;
`

const CarouselSectionBlock = styled.div`
  background: ${colors.fadedPurple};
`

const TogglsManifestoTextColumns = styled.div`
  margin-top: 103px;
  margin-bottom: 128px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  column-gap: 20px;
  row-gap: 20px;

  ${media.mq[1]} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    column-gap: 210px;
    row-gap: 70px;

    > div:nth-child(2n) {
      transform: translateY(80px);
    }
  }
`

const TogglsManifestoHeader = styled.div`
  span {
    margin-left: 70px;
  }
`

const TogglsManifestoWrapper = styled.div`
  ${media.mq[1]} {
    display: flex;
    max-width: 1200px;
    flex-direction: column;
  }
`

const TogglsManifesto = styled.div`
  background: ${colors.darkPurple};
  h1,
  h3,
  h4 {
    color: ${(p) => p.theme.primary};
  }
  h1 + p,
  h2 + p,
  h3 + p,
  h4 + p,
  h5 + p {
    margin-top: 20px;
  }
`

const TogglsToolsBottomHeaderMobile = styled.div`
  width: 200px;
  font-size: 3rem;
  ${media.mq[1]} {
    display: none;
  }
`

const TogglsToolsBottomHeaderDesktopH1 = styled.div`
  ${media.mq[1]} {
    h1 {
      color: ${(p) => p.theme.primary};
    }
    display: block;
    width: 468px;
    margin-left: 122px;

    & > span {
      margin-left: 100px;
    }
    & > p {
      margin-top: 54px;
    }
  }
`

const TogglsToolsBottomHeaderDesktop = styled.div`
  display: none;
  ${media.mq[1]} {
    display: flex;
    height: 468px;
    justify-content: center;

    video {
      max-width: 460px;
    }
  }
`

const TogglsToolsBottom = styled.div`
  padding: 56px 30px;
  background: ${colors.fadedPurple};

  ${media.mq[1]} {
    padding: 0;
  }
`

const TogglsToolsHeader = styled.div`
  width: 165px;
  padding-top: 53px;
  padding-bottom: 27px;
  text-align: center;
  h1 {
    ${text.heading1};
    color: ${(p) => p.theme.primary};
  }

  ${media.mq[1]} {
    width: 100%;
  }
`

const TogglsTools = styled(Card.ProductSection)`
  padding-top: 16px;
  position: relative;
  overflow: hidden;
  background: ${colors.fadedPurple};

  & > div {
    z-index: 1;
  }

  ${media.mq[1]} {
    height: 740px;
    padding-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`

const SkewedTextRightSectionP = styled.div`
  ${text.paragraph3};
  ${media.mq[1]} {
    margin-top: 22px;
  }
`

const SkewedTextRightSectionWrapper = styled.div`
  padding-bottom: 32px;

  ${media.mq[1]} {
    margin-top: 218px;
    margin-left: 120px;
    width: 400px;
  }
`

const SkewedTextLeftSectionWrapper = styled.div`
  padding-bottom: 32px;

  ${media.mq[1]} {
    width: 488px;
  }
`

const SkewedTextSectionWrapper = styled.div`
  padding-top: 30px;

  ${media.mq[1]} {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
  }
`

const ImgMap = styled.img`
  display: none;

  ${media.mq[1]} {
    display: block;
    margin-left: 20px;
    margin-top: 153px;
    height: 338px;
  }
`

const OurStoryHeaderWrapper = styled.div`
  h1 {
    color: ${(p) => p.theme.primary};
  }
  h1 + p {
    margin-top: 30px;
  }

  ${media.mq[1]} {
    max-width: 430px;
  }
`

const OurStory = styled.div`
  display: flex;

  img {
    margin-left: auto;
  }
`

const OurStorySection = styled.div`
  background: ${colors.darkPurple};
`

const HeaderWrapper = styled.div`
  text-align: center;
  margin-top: 57px;
  margin-bottom: 120px;
  padding: 0 11px;

  em {
    color: ${(p) => p.theme.primary};
  }
`

export default GlobalMissionPage
